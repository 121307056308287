import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";


const LegalPage = () => (
    <Layout>
        <Seo title={'Website Terms and Conditions | Nemo Personal Finance'} description={'Click here now to read the legal terms of use for accessing and using the Nemo Personal Finance website.'} lang={'en-gb'} />
        <div className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Website Terms and Conditions</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
                                SITE</p>
                        </div>
                    </div>
                    <p>
                        <strong>WHAT'S IN THESE TERMS?<br /><br /></strong>These terms tell you the rules for using our
                        website <a href="/">www.nemo-loans.co.uk</a> (our site).<br /><br />The
                        below terms set out:</p>
                    <p>•&nbsp;Who we are and how to contact us<br />•&nbsp;Acceptance of our terms<br />•&nbsp;Other terms
                        that may apply when you are using our site<br />•&nbsp;How we may change, suspend or withdraw our
                            site<br />•&nbsp;Our site is only for UK consumers<br />•&nbsp;Permitted uses of our
                                site<br />•&nbsp;Relying on information on our site<br />•&nbsp;Linking to third party
                                    websites from our site <br />•&nbsp;Responsibility for our site<br />•&nbsp;Linking to
                                        our site<br />•&nbsp;Law that applies to our site<br />•&nbsp;Our trademarks</p>
                    <p>
                        <strong>WHO WE ARE AND HOW TO CONTACT US</strong> <br /><br /><a
                        href="/">www.nemo-loans.co.uk</a> is a site operated by Nemo Personal
                        Finance Limited ("We"). We are registered in England and Wales under company number 05188059 and
                        have our registered office at&nbsp;Principality Building Society, PO Box 89, Principality
                        Buildings, Queen Street, Cardiff, CF10 1UA. Our main trading address is Trafalgar House, 5
                        Fitzalan Place, Cardiff CF24 0ED. Our VAT number is 615 9386 16.<br /><br />We are authorised and
                            regulated by the Financial Conduct Authority. We subscribe the Lending code which is
                            monitored and enforced by the FLA (Finance and Leasing Association). <br /><br />We are a
                                limited company.<br /><br />To contact us, please email <a
                                    href="mailto:customerservices@nemoloans.co.uk">customerservices@nemoloans.co.uk</a> or
                                    for a contact number please click <a
                                        href="/contact-us">here</a>.<br /><br /><strong>BY USING OUR SITE YOU ACCEPT
                                        THESE TERMS<br /><br /></strong>By using our site, you confirm that you accept these
                                        terms of use and that you agree to comply with them.<br /><br />If you do not agree
                                            to these terms, you must not use our site.<br /><br />We recommend that you
                                                print a copy of these terms for future reference.<br /><br /><strong>THERE
                                                    ARE OTHER TERMS THAT MAY APPLY TO YOU<br /></strong><br />These terms of
                                                    use refer to the following additional terms, which also apply to
                                                    your use of our site:</p>
                    <ul>
                        <li>Our <a href="/privacy-policy">Privacy Policy</a>, which sets out the terms on which we
                            process any personal data we collect about you, or that you provide to us. By using our
                            site, you consent to such processing and you agree that all data provided by you is
                            accurate.
                        </li>
                        <li>Our <a href="/cookies">Cookie Policy</a>, which sets out information about the cookies
                            on our site. We may make changes to these terms.
                        </li>
                    </ul>
                    <p>We amend these terms from time to time. Every time you wish to use our site, please check these
                        terms to ensure you understand the terms that apply at that time. Any changes will only apply to
                        future use (and not to information already downloaded). <br /><br /><strong>WE MAY MAKE CHANGES TO
                            OUR SITE</strong><br /><br />We may update and change our site from time to time to reflect
                            changes to our services, our users' needs, our business priorities, or for legal and
                            regulatory requirements. <br /><br /><strong>WE MAY SUSPEND OR WITHDRAW OUR
                                SITE</strong><br /><br />Our site is made available free of charge.<br /><br />We do not
                                guarantee that our site, or any content on it, will always be available or be
                                uninterrupted. We may suspend or withdraw or restrict the availability of all or any
                                part of our site for business and operational reasons. We will try to give you
                                reasonable notice of any suspension or withdrawal.<br /><br />You are also responsible for
                                    ensuring that all persons who access our site through your internet connection are
                                    aware of these terms of use and other applicable terms and conditions, and that they
                                    comply with them.<br /><br /><strong>OUR SITE IS ONLY FOR USERS IN THE
                                        UK<br /><br /></strong>Our site is directed to people residing in the United
                                        Kingdom. We do not represent that content available on or through our site is
                                        appropriate for use or available in other locations. <br /><br />You must keep your
                                            account details safe.<br /><br />If you choose, or you are provided with, a user
                                                identification code, password or any other piece of information as part
                                                of our security procedures, you must treat such information as
                                                confidential. You must not disclose it to any third party.<br /><br />We
                                                    have the right to disable any user identification code or password,
                                                    whether chosen by you or allocated by us, at any time, if in our
                                                    reasonable opinion you have failed to comply with any of the
                                                    provisions of these terms of use.<br /><br />If you know or suspect that
                                                        anyone other than you knows your user identification code or
                                                        password, you must promptly notify us at <a
                                                            href="mailto:customerservices@nemoloans.co.uk">customerservices@nemoloans.co.uk</a><br /><br /><strong>HOW
                                                            YOU MAY USE MATERIAL ON OUR SITE </strong><br /><br />We are the
                                                            owner or the licensee of all intellectual property rights in
                                                            our site, and in the material published on it.&nbsp; Those
                                                            works are protected by copyright laws and treaties around
                                                            the world. All such rights are reserved.<br /><br />You may
                                                                print off one copy, and may download extracts, of any
                                                                page(s) from our site for your personal use and you may
                                                                draw the attention of others within your organisation to
                                                                content posted on our site.<br /><br />You must not modify
                                                                    the paper or digital copies of any materials you
                                                                    have printed off or downloaded in any way, and you
                                                                    must not use any illustrations, photographs, video
                                                                    or audio sequences or any graphics separately from
                                                                    any accompanying text.<br /><br />Our status (and that
                                                                        of any identified contributors) as the authors
                                                                        of content on our site must always be
                                                                        acknowledged.<br /><br />You must not use any part
                                                                            of the content on our site for commercial
                                                                            purposes without obtaining a licence to do
                                                                            so from us or our licensors.<br /><br />If you
                                                                                print off, copy or download any part of
                                                                                our site in breach of these terms of
                                                                                use, your right to use our site will
                                                                                cease immediately and you must, at our
                                                                                option, return or destroy any copies of
                                                                                the materials you have
                                                                                made.<br /><br /><strong>PROHIBITED USES OF
                                                                                    OUR SITE<br /></strong><br />You may use
                                                                                    our site only for lawful purposes.
                                                                                    You may not use our site:</p>
                    <ul>
                        <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or
                            effect
                        </li>
                        <li>For the purpose of harming or attempting to harm other people in any way</li>
                        <li>To send, knowingly receive, upload, download, use or re-use any material which: <ul></ul>
                        </li>
                        <li>Contain any material which is defamatory of any person</li>
                        <li>Contain any material which is obscene, offensive, hateful or inflammatory</li>
                        <li>Promote sexually explicit material</li>
                        <li>Promote violence</li>
                        <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual
                            orientation or age
                        </li>
                        <li>Infringe any copyright, database right or trade mark of any other person</li>
                        <li>Be likely to deceive any person</li>
                        <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a
                            duty of confidence
                        </li>
                        <li>Promote any illegal activity</li>
                        <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or
                            needless anxiety
                        </li>
                        <li>Be likely to harass, upset, embarrass, alarm or annoy any other person</li>
                        <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any
                            person
                        </li>
                        <li>Give the impression that they emanate from us, if this is not the case</li>
                        <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright
                            infringement or computer misuse
                        </li>
                        <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or
                            promotional material or any other form of similar solicitation (spam)
                        </li>
                        <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan
                            horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs
                            or similar computer code designed to adversely affect the operation of any computer software
                            or hardware
                        </li>
                        <li>You also agree: <ul></ul></li>
                        <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the
                            provisions of the above section “How you may use material on our site”
                        </li>
                        <li>Not to access without authority, interfere with, damage or disrupt</li>
                        <li>any part of our site</li>
                        <li>any equipment or network on which our site is stored</li>
                        <li>any software used in the provision of our site or</li>
                        <li>any equipment or network or software owned or used by any third party</li>
                    </ul>
                    <p>
                        <strong>DO NOT RELY ON INFORMATION ON THIS SITE</strong> <br /><br />The content on our site is
                        provided for general information only. It is not intended to amount to advice on which you
                        should rely. You must obtain professional or specialist advice before taking, or refraining
                        from, any action on the basis of the content on our site.<br /><br />Although we make reasonable
                            efforts to update the information on our site, we make no representations, warranties or
                            guarantees, whether express or implied, that the content on our site is accurate, complete
                            or up to date.<br /><br /><strong>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK
                                TO<br /><br /></strong>Where our site contains links to other sites and resources provided
                                by third parties, these links are provided for your information only. Such links should
                                not be interpreted as approval by us of those linked websites or information you may
                                obtain from them.<br /><br />We have no control over the contents of those sites or
                                    resources.<br /><br /><strong>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY
                                        YOU</strong></p>
                    <ul>
                        <li>Please note that we only provide our site for domestic and private use, not for commercial
                            or business use. You agree not to use our site for any commercial or business purposes, and
                            we have no liability to you for any loss of profit, loss of business, business interruption,
                            or loss of business opportunity.
                        </li>
                        <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do
                            so. This includes liability for death or personal injury caused by our negligence or the
                            negligence of our employees, agents or subcontractors and for fraud or fraudulent
                            misrepresentation.
                        </li>
                    </ul>
                    <p>
                        <strong>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</strong> <br /><br />We
                        do not guarantee that our site will be secure or free from bugs or viruses.<br /><br />You are
                            responsible for configuring your information technology, computer programmes and platform to
                            access our site. You should use your own virus protection software.<br /><br />You must not
                                misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other
                                material that is malicious or technologically harmful. You must not attempt to gain
                                unauthorised access to our site, the server on which our site is stored or any server,
                                computer or database connected to our site. You must not attack our site via a
                                denial-of-service attack or a distributed denial-of service attack. By breaching this
                                provision, you would commit a criminal offence under the Computer Misuse Act
                                1990. <br /><br />We will report any such breach to the relevant law enforcement authorities
                                    and we will co-operate with those authorities by disclosing your identity to them.
                                    In the event of such a breach, your right to use our site will cease
                                    immediately.<br /><br /><strong>RULES ABOUT LINKING TO OUR SITE </strong><br /><br />You may
                                        link to our home page, provided you do so in a way that is fair and legal and
                                        does not damage our reputation or take advantage of it.<br /><br />You must not
                                            establish a link in such a way as to suggest any form of association,
                                            approval or endorsement on our part where none exists.<br /><br />You must not
                                                establish a link to our site in any website that is not owned by
                                                you.<br /><br />Our site must not be framed on any other site, nor may you
                                                    create a link to any part of our site other than the home
                                                    page.<br /><br />We reserve the right to withdraw linking permission
                                                        without notice. If you wish to link to or make any use of
                                                        content on our site other than that set out above, please
                                                        contact <a
                                                            href="mailto:customerservices@nemoloans.co.uk">customerservices@nemoloans.co.uk</a><br /><br /><strong>WHICH
                                                            COUNTRY'S LAWS APPLY TO ANY DISPUTES?<br /><br /></strong>These
                                                            terms of use, their subject matter and their formation, are
                                                            governed by the laws of England and Wales. You and we both
                                                            agree that the courts of England and Wales will have
                                                            exclusive jurisdiction except that if you are a resident of
                                                            Northern Ireland you may also bring proceedings in Northern
                                                            Ireland, and if you are resident of Scotland, you may also
                                                            bring proceedings in Scotland.<br /><br /><strong>OUR TRADE
                                                                MARKS ARE REGISTERED<br /></strong><br />Nemo Personal
                                                                Finance is a registered trade mark of Nemo Personal
                                                                Finance Limited. You are not permitted to use this
                                                                without our approval, unless it is part of material you
                                                                are using as permitted under How you may use material on
                                                                our site. <br /></p>
                    <p>
                    </p>

                </div>
                <div className="bdrBot"></div>
            </div>
        </div>
    </Layout>
)

export default LegalPage;